<section class="container py-4">
    <div class="row">
        <div class="col-md-12">
            <ul id="tabsJustified" class="nav nav-tabs iris-gst-display-flex">
                <li class="nav-item">
                    <a href="" data-target="#addBusiness" data-toggle="tab" class="nav-link " data-toggle="tab"
                        class="nav-link " [ngClass]="activeStep1 ? 'active': ''" (click)="OpenForm('step1')">User
                        Profile</a>
                </li>
                <li class="nav-item"><a href="" data-target="#subscribe" data-toggle="tab" class="nav-link "
                        [ngClass]="activeStep2 ? 'active': ''" (click)="OpenForm('step2')">Change Password</a></li>
                <li class="nav-item"><a href="" data-target="#mfa" data-toggle="tab" class="nav-link "
                        [ngClass]="activeStep3 ? 'active': ''" (click)="OpenForm('step3')">Multi-Factor
                        Authentication</a></li>
                <li class="nav-item"><a href="" data-target="#auditLogs" data-toggle="tab" class="nav-link "
                        [ngClass]="activeStep4 ? 'active': ''" (click)="OpenForm('step4')">Audit Logs</a></li>

            </ul>
            <br>
            <div id="tabsJustifiedContent" class="tab-content card">
                <div id="addBusiness" class="tab-pane fade " [ngClass]="activeStep1 ? 'active show': ''"
                    *ngIf="activeStep1">
                    <div class="row pb-2">
                        <form id="example-form" class="col-md-12" [formGroup]="updateProfileForm"
                            *ngIf="userEmail != null">
                            <div class="form-group">
                                <label>First Name <sup>*</sup></label>
                                <input type="text" class="form-control col-md-8" formControlName="username"
                                    placeholder="Enter First Name"
                                    [ngClass]="{ 'has-danger': isSubmitted && updateProfileForm.get('username').errors }">
                                <div *ngIf="isSubmittedProfile">
                                    <span class="text-danger"
                                        *ngIf="!updateProfileForm.get('username').dirty && updateProfileForm.get('username').errors?.required">Please
                                        enter First Name
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Middle Name </label>
                                <input type="text" class="form-control col-md-8" formControlName="middlename"
                                    placeholder="Enter Middle Name">

                            </div>
                            <div class="form-group">
                                <label>Surname <sup>*</sup></label>
                                <input type="text" class="form-control col-md-8" formControlName="lastname"
                                    placeholder="Enter Surname"
                                    [ngClass]="{ 'has-danger': isSubmitted && updateProfileForm.get('lastname').errors }">
                                <div *ngIf="isSubmittedProfile">
                                    <span class="text-danger"
                                        *ngIf="!updateProfileForm.get('lastname').dirty && updateProfileForm.get('lastname').errors?.required">Please
                                        enter Last Name
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="pan">Mobile Number <sup>*</sup> </label>
                                <input type="text" class="form-control col-md-8" placeholder="Enter Mobile Number"
                                    formControlName="phonenumer">
                                <div *ngIf="isSubmittedProfile">
                                    <span class="text-danger"
                                        *ngIf="!updateProfileForm.get('phonenumer').dirty && updateProfileForm.get('phonenumer').errors?.required">Please
                                        enter Mobile Number
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="updateProfileForm.get('phonenumer').dirty && updateProfileForm.get('phonenumer').errors">Please
                                        enter valid Mobile Number
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="pan">Email Id<sup>*</sup> </label>
                                <input type="text" class="form-control col-md-8" placeholder="Enter Email Id"
                                    formControlName="email" readonly>
                                <div *ngIf="isSubmittedProfile">
                                    <span class="text-danger"
                                        *ngIf="!updateProfileForm.get('email').dirty && updateProfileForm.get('email').errors?.required">Please
                                        enter Email Id
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="updateProfileForm.get('email').dirty && updateProfileForm.get('email').errors">Please
                                        enter valid Email Id
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Notification Email Id<sup>*</sup> </label>
                                <input type="text" class="form-control col-md-8"
                                    placeholder="Enter Notification Email Id" formControlName="notificationEmail">
                                <div *ngIf="isSubmittedProfile">
                                    <span class="text-danger"
                                        *ngIf="!updateProfileForm.get('notificationEmail').dirty && updateProfileForm.get('notificationEmail').errors?.required">Please
                                        enter Notification Email Id
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="updateProfileForm.get('notificationEmail').dirty && updateProfileForm.get('notificationEmail').errors">Please
                                        enter valid Notification Email Id
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Aadhaar Number</label>
                                <input type="text" class="form-control col-md-8" placeholder="Enter Aadhaar Number"
                                    formControlName="aadhaar">
                                <div *ngIf="isSubmittedProfile">
                                    <span class="text-danger"
                                        *ngIf="updateProfileForm.get('aadhaar').dirty && updateProfileForm.get('aadhaar').errors">Aadhaar
                                        number should be numeric and 12 digit, Never starts with 0 & 1.
                                    </span>
                                </div>
                            </div>

                        </form>
                    </div>
                    <button type="submit" class="btn btn-primary" (click)="updateProfile()">Submit</button>
                    <button class="btn btn-light ml-1" (click)="toDashboard()">Back</button>
                </div>
                <div id="subscribe" class="tab-pane fade">
                    <div class="row pb-2">
                        <form class="col-md-12" [formGroup]="updatePassForm">
                            <div class="form-group">
                                <label for="companyname">Current Password <sup>*</sup></label>
                                <input type="password" class="form-control col-md-8 " formControlName="curentpassword"
                                    placeholder="Current Password">
                                <div *ngIf="isSubmitted && f.curentpassword.errors">
                                    <span class="text-danger" *ngIf="f.curentpassword.errors.required">Current Password
                                        Required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="!f.curentpassword.errors.required && f.curentpassword.errors">Invalid
                                        Current
                                        Password
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="companyname">New Password <sup>*</sup></label>
                                <input type="password" class="form-control col-md-8 " formControlName="newpassword"
                                    placeholder="New Password">
                                <div *ngIf="isSubmitted && f.newpassword.errors">
                                    <span class="text-danger" *ngIf="f.newpassword.errors.required">New Password
                                        Required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="!f.newpassword.errors.required && f.newpassword.errors">Invalid New
                                        Password
                                    </span>
                                </div>
                                <div class="col-md-8 clrOrange">
                                    <p class="noteText"><i class="fa fa-info-circle"></i> Password must be of min 8 and
                                        max 16 characters in length consisting of at least 1 capital and 1 small letter,
                                        1 number and 1 special character. The special characters allowed are @ # $ % ^ &
                                        + = . [ ] * ?</p>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="companyname">Confirm Password <sup>*</sup></label>
                                <input type="password" class="form-control col-md-8 " formControlName="confirmpassword"
                                    placeholder="Confirm Password">
                                <div *ngIf="isSubmitted && f.confirmpassword.errors">
                                    <span class="text-danger" *ngIf="f.confirmpassword.errors.required">Confirm Password
                                        Required
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <button type="submit" class="btn btn-primary" (click)="updatePassword()">Finish</button>
                    <button class="btn btn-light ml-1" (click)="toDashboard()">Back</button>
                </div>
                <div id="mfa" class="tab-pane fade">
                    <div *ngIf="!qrData && !mfaDetails">
                        <div class=" jumbotron clearfix my-lg-4">
                            <h2 class="text-center">Registration for Multi Factor Authentication (MFA)</h2>
                            <div class="no-gutters p-md-5 p-2">
                                <div>
                                    You can opt in for multi factor Authentication and receive OTP -
                                </div>
                                <div>
                                    1. OTP can be generated by using Microsoft / Google Authenticator App.
                                    You may download the app from the playstore.
                                </div>
                                <div>
                                    2. Once you wish to Opt in, scan the QR code image in IRIS GST application by using
                                    Microsoft/Google Authenticator App.
                                </div>
                                <div>
                                    3. You may enter any of the OTP received and registration for multi Factor
                                    authentication is completed successfully.
                                </div>
                                <div>
                                    4. Once enabled, the system will prompt for an OTP on every login attempt,
                                    following the entry of the username and password.
                                </div>
                                <!-- <div>
                                    5. You may de-register this facility by writing a mail at "support@easywaybill.in".
                                </div> -->

                                <div>
                                    Do you wish to register for OTP based login ?
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <button class="btn btn-primary" (click)="fetchQRCode()">Confirm</button>
                            <button class="btn btn-light ml-1" (click)="toDashboard()">Cancel</button>
                        </div>
                    </div>
                    <div *ngIf="qrData && !mfaDetails">
                        <div class="clearfix my-lg-4">
                            <h6 class="text-center"> To complete MFA registration, scan the QR code with Google or
                                Microsoft Authenticator,
                                generate a One-Time Password (OTP), and enter it into the designated field before it
                                expires. </h6>
                            <div class="row no-gutters p-md-5 p-2">
                                <form class="row col-md-12" [formGroup]="otpForm">
                                    <div class="form-group col-md-6">
                                        <img [src]="qrData.qrCodeURL" alt="mfa qr code" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="companyname">MFA Code <sup>*</sup></label>
                                        <input type="text" class="form-control col-md-8 " formControlName="code"
                                            placeholder="MFA Code">
                                        <div *ngIf="isSubmittedProfile">
                                            <span class="text-danger" *ngIf="otpForm.get('code').errors">Please enter
                                                valid code
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="">
                            <button type="submit" class="btn btn-primary" (click)="verifyOtp()">Verify</button>
                            <button class="btn btn-light ml-1" (click)="toDashboard()">Cancel</button>
                        </div>

                    </div>
                    <div *ngIf="mfaDetails">
                        <h4 class="text-center">Multi Factor Authentication Details</h4>
                        <div class="col-md-12">
                            <div class="" id="mfaDetails">
                                <div class="row col-md-12">
                                    <label for="pan_num" class="col-md-6 txt-end"><b>Device Type
                                        </b>:</label>
                                    <div class="col-md-6 paddbt15">
                                        Virtual
                                    </div>
                                </div>
                                <div class="row col-md-12">
                                    <label for="pan_num" class="col-md-6 txt-end"><b>Register On
                                        </b>:</label>
                                    <div class="col-md-6 paddbt15">
                                        {{mfaDetails.registerOn}}
                                    </div>
                                </div>
                                <div class="row col-md-12">
                                    <label for="pan_num" class="col-md-6 txt-end"><b>Opt Out
                                        </b>:</label>
                                    <div class="col-md-6 paddbt15">
                                        <input class="cursor-pointer" type="checkbox" id="optOut" data-toggle="modal"
                                            data-target="#exampleModal" name="optOut" [(ngModel)]="optOut">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="auditLogs" class="tab-pane fade">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table id="" class="table">
                                    <thead>
                                        <tr class="bg-primary text-dblue">
                                            <th>Sr. No.</th>
                                            <th>User Name</th>
                                            <th>Action</th>
                                            <th>Requested Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let obj of auditList;let i = index;">
                                            <td>{{i+1}}</td>
                                            <td>{{obj.userEmailId}}</td>
                                            <td>{{obj.action}}</td>
                                            <td>{{obj.timestamp | date: 'dd/MM/yyyy hh:mm a'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="list mt_10" *ngIf="auditList.length > 0">
                                <p-paginator [rows]="10" totalRecords="{{totalRecord}}"
                                    [rowsPerPageOptions]="[10, 50, 100]" [first]="firstParam"
                                    (onPageChange)="onPageChange($event)"></p-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" (click)="optOut=false;" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure you want to deactivate Multi Factor Authentication ?
            </div>
            <div class="modal-footer">
                <button type="button" (click)="optOut=false;" class="btn btn-secondary"
                    data-dismiss="modal">Cancel</button>
                <button type="button" (click)="onOptOutChange()" class="btn btn-primary"
                    data-dismiss="modal">Confirm</button>
            </div>
        </div>
    </div>
</div>
